import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { LoginedEmployee } from 'src/app/pages/teams/models/reponse/logined-employee';
import { environment } from 'src/environments/environment';
import { UserRightPermission } from '../models/UserRightPermissions';
import { Zoom } from '../models/zoom';
import { LicensePlan } from '../enum/license-plan.enum';
import { LoginedUserModel } from '../models/logined-user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    user: LoginedUserModel = null;

    constructor() {
        this.getDecodedAccessToken();
    }

    getDecodedAccessToken() {
        if (localStorage.getItem(environment.authTokenKey)) {
            let userToken = localStorage.getItem(environment.authTokenKey);
            this.user = jwt_decode<any>(userToken);
            return this.user;
        } else {
            return null;
        }
    }

    getEmployeeId(): number {
        return this.user ? +this.user.employeeId : null;
    }

    getLicenseKey(): string {
        let licenseKey: string = '';
        licenseKey = this.user && this.user?.license_key ? this.user?.license_key : '';
        return licenseKey;
    }

    getLicensePlan(): string {
        let licensePlan: string = LicensePlan.Basic;
        licensePlan = this.user && this.user?.payment_plan ? this.user?.payment_plan : LicensePlan.Basic;
        return licensePlan;
    }

    isPaymentDone(): boolean {
        let isPaymentDone: boolean = false;
        isPaymentDone = this.user && this.user?.paymentStatus === 'True' ? true : false;
        return isPaymentDone;
    }

    getLoginedEmployee(): LoginedEmployee {
        let employee: LoginedEmployee = this.user && this.user?.employee ? this.user?.employee : null;
        return employee;
    }

    isAdmin(): boolean {
        let isAdmin: boolean = false;
        if (this.user && this.user.isAdmin && this.user.isAdmin === 'True') {
            isAdmin = true;
        }
        return isAdmin;
    }

    isExternalEmployee(): boolean {
        let isExternalEmployee: boolean = false;
        if (this.user && this.user.isExternalEmployee && this.user.isExternalEmployee === 'True') {
            isExternalEmployee = true;
        }
        return isExternalEmployee;
    }

    getUserRightPermission(): UserRightPermission {
        let userRightPermission: UserRightPermission = {
            CanCreateMeeting: false,
            CanCreateProject: false,
            CanDownloadMeeting: false,
            CanViewAllClient: false,
            CanViewAllMeeting: false,
            CanViewAllProject: false,
            CanCreateEmployee: false
        };

        if (this.user && this.user.userRightPermission) {
            userRightPermission.CanCreateMeeting =
                this.user.userRightPermission?.CanCreateMeeting ? this.user.userRightPermission?.CanCreateMeeting : false;

            userRightPermission.CanCreateProject =
                this.user.userRightPermission?.CanCreateProject ? this.user.userRightPermission?.CanCreateProject : false;

            userRightPermission.CanDownloadMeeting =
                this.user.userRightPermission?.CanDownloadMeeting ? this.user.userRightPermission?.CanDownloadMeeting : false;

            userRightPermission.CanViewAllProject =
                this.user.userRightPermission?.CanViewAllProject ? this.user.userRightPermission?.CanViewAllProject : false;

            userRightPermission.CanViewAllMeeting =
                this.user.userRightPermission?.CanViewAllMeeting ? this.user.userRightPermission?.CanViewAllMeeting : false;

            userRightPermission.CanViewAllClient =
                this.user.userRightPermission?.CanViewAllClient ? this.user.userRightPermission?.CanViewAllClient : false;

            userRightPermission.CanCreateEmployee =
                this.user.userRightPermission?.CanCreateEmployee ? this.user.userRightPermission?.CanCreateEmployee : false;
        }
        return userRightPermission;
    }

    getClientId(): number {
        return this.user && this.user?.client_id ? + this.user.client_id : null;
    }

    getUserName(): string {
        return this.user && this.user.username ? this.user.username : '';
    }

    getZoomDetails(): Zoom {
        let zoomDetails: Zoom = {
            zoom_api_key: this.user?.zoom_api_key,
            zoom_api_secret: this.user?.zoom_api_secret,
            zoom_userName: this.user?.zoom_userName
        };
        return zoomDetails;
    }

    getIsFirstTimeLogin(): string {
        return this.user && this.user.isFirstTimeLogin ? this.user.isFirstTimeLogin : "False";
    }

    getOrganizerName(): string {
        return this.user && this.user?.employee?.FirstName ? this.user?.employee?.FirstName : '';
    }

    getOrganizerEmail(): string {
        return this.user && this.user?.employee?.Email ? this.user?.employee?.Email : '';
    }

    getTenantId() {
        return this.user && this.user?.firebase_tenant_Id ? this.user?.firebase_tenant_Id : '';
    }

    getFirebaseInstance(): string {
        return this.user && this.user.firebae_instance ? this.user.firebae_instance : '';
    }

    getCurrentLocalId(): string {
        return this.user && this.user?.employee?.LocalId ? this.user?.employee?.LocalId : '';
    }

    getPhotoPath(): string {
        return this.user && this.user?.employee?.PhotoPath ? this.user?.employee?.PhotoPath : '';
    }

    getCurrentUserName(): string {
        return this.user && this.user?.employee?.FirstName ? this.user?.employee?.FirstName : '';
    }
}
