import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Employee } from 'src/app/pages/teams/models/employee.read.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NotificationRequestModel } from '../models/notification-request';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  employeeData: Employee;
  projectData: any;
  private projectId: number;
  private _ProjectId: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _isTaskInserted: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient,) { }

  //Project data And Id 
  public setProjectId(id: any) {
    this.projectId = id;
    this._ProjectId.next(id);
  }

  getProjectById(id: number | string): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + `Project/${id}`;
    return this.http.get(apiUrl);
  }

  getClientById(id: number): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + `Client/${id}`;
    return this.http.get(apiUrl);
  }

  getMeetingById(id: number): Observable<any> {
    const apiUrl = environment.REST_API_SERVER_URL + `Meeting/${id}`;
    return this.http.get(apiUrl);
  }

  getFollowUpsByFilter(filter: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'FollowUp/Filter', filter);
  }

  getRemindersByFilter(filter: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'Reminder/Filter', filter);
  }

  getEmployeesByFilter(filterData: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'Employee/Filter', filterData);
  }

  getEmployeesForDropdown(filterData: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'Employee/GetAll', filterData);
  }

  getClientsByFilter(filterData: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'Client/Filter', filterData);
  }

  getProjectMembersByProjectId(id: number): Observable<any> {
    return this.http.get(environment.REST_API_SERVER_URL + `ProjectMember/GetByProjectId/${id}`);
  }

  getProjectTaskTypesByProjectId(id: number): Observable<any> {
    return this.http.post( environment.REST_API_SERVER_URL + `ProjectTaskType/GetAllByProjectId?projectId=${id}`, {} );
  }

  getTotalPipelineAmount(data: any): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'Sale/Total', data);
  }

  clearNotification(requestData: NotificationRequestModel): Observable<any> {
    return this.http.post(environment.REST_API_SERVER_URL + 'Notification/ClearNotification', requestData);
  }

  uploadFile(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("Photo", file);
    const apiUrl = environment.REST_API_SERVER_URL + "Photo/Insert";
    return this.http.post(apiUrl, formData);
  } 

  public getProjectId(): Observable<any> {
    return this._ProjectId.asObservable();
  }
  public getProjectIdSingle() {
    return this.projectId;
  }
  setProjectData(data) {
    this.projectData = data;
  }
  getProjectData() {
    return this.projectData;
  }

  //End Project data And Id 


  //get Team Employee Data and Id 
  setSelectedEmployee(data) {
    this.employeeData = data;
  }
  getSelectedEmployee() {
    return this.employeeData;
  }

  //is Task Inserted
  public setIsTaskInseted(data: any) {
    this._isTaskInserted.next(data);
  }

  public getIsTaskInserted(): Observable<any> {
    return this._isTaskInserted.asObservable();
  }


  GetAllDepartment() {
    const apiUrl = environment.REST_API_SERVER_URL + 'Department/GetAll';
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('Department'))
    );
  }


  // api/Designation/GetAll

  DesignationGetAll() {
    const apiUrl = environment.REST_API_SERVER_URL + 'Designation/GetAll';
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('Designation'))
    );
  }


  // /api/Country/GetAll

  GetAllCountry() {
    const apiUrl = environment.REST_API_SERVER_URL + 'Country/GetAll';
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('Country'))
    );
  }

  getProjectFilter(employeeId: number): Observable<any> {
    // const apiUrl = environment.REST_API_SERVER_URL + `Project/Filter?PageNo=${pageNo}&PageSize=${pageSize}&IsActive=true&MyOwnProjects=true`;
    const apiUrl = environment.REST_API_SERVER_URL + `Project/DropDown?IsActive=true&EmployeeId=${employeeId}&IncludeGeneral=true`;
    return this.http.get(apiUrl);
  }

  // ProjectModule/GetByProjectId
  GetProjectModule(id: number | string) {
    const apiUrl = environment.REST_API_SERVER_URL + `ProjectModule/GetByProjectId/${id}`;
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('ProjectModule'))
    );
  }

  GetProjectMemberById(id: number | string) {
    const apiUrl = environment.REST_API_SERVER_URL + `ProjectMember/GetByProjectId/${id}`;
    return this.http.get(apiUrl).pipe(
      catchError(this.handleError<any>('ProjectModule'))
    );
  }

  getCompanyDetails(id: number) {
    return this.http.get(environment.REST_API_SERVER_URL + `Client/GetDetail/${id}`);
  }

  sweetAlertDialog(position?, icon?, messgae?) {
    Swal.fire({
      position: position,  //'top-end',
      icon: icon,   //'success', 
      title: messgae,
      showConfirmButton: true,
      timer: 3000
    })
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) {
    //this.messageService.add(`AcGroupService: ${message}`);
  }
}
